export const country = {
  italianRegions: [
    'Abruzzo',
    'Basilicata',
    'Calabria',
    'Campania',
    'Emilia-Romagna',
    'Friuli Venezia Giulia',
    'Lazio',
    'Liguria',
    'Lombardia',
    'Marche',
    'Molise',
    'Piemonte',
    'Puglia',
    'Sardegna',
    'Sicilia',
    'Toscana',
    'Trentino-Alto Adige',
    'Umbria',
    'Valle d\'Aosta',
    'Veneto'
  ]
};

export const soccerRole = {
  DC: 'Difensore Centrale',
  TD: 'Terzino Destro',
  TS: 'Terzino Sinistro',
  CM: 'Mediano',
  CR: 'Regista',
  CMZ: 'Mezzala',
  CE: 'Esterno',
  ACN: 'Centravanti',
  AEO: 'Esterno Offensivo',
  AF9: 'Falso Nove',
  ASP: 'Seconda Punta'
}

export const soccerTrainingType = {
  TS1: 'Classico',
  TS2: 'Tattica',
  TS3: 'Resistenza',
  TS4: 'Amichevole',
  TS5: 'Cardio',
  TS6: 'Calci Piazzati',
  TS7: 'Preparazione',
  TS8: 'Rifinitura'
}
