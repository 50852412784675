import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BetaService {

  constructor(private http: HttpClient) { }

  betaProgram(request: any): Observable<any> {
    return this.http.post(environment.apiUrl + '/v1/beta', request);
  }
}
