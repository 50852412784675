import {Injectable} from '@angular/core';
import {AuthService} from '../service/auth.service';
import {UserService} from '../../standalone/service/user.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ClubInitializerService {

  constructor(private authService: AuthService,
              private userService: UserService,
              private router: Router) { }

  async initializeApp(): Promise<any> {
    const token = localStorage.getItem('club-token');
    if(token) {
      this.authService.authOn(token);
      this.userService.roles(this.authService.getValueFromToken('id')).subscribe(res => {
        if(!(res.clubs && res.clubs.length > 0)) {
          this.router.navigate(['setup']);
        }
      });
    }
    return Promise.resolve();
  }
}
